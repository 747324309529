import { Action } from '@ngrx/store';
import { Fraction } from './../utils/fraction.util';

import { Ratio } from './../ratio';

export const RATIO_ACTIONS = {
  CALCULATE_HEIGHT: 'CALCULATE_HEIGHT',
  CALCULATE_RATIO: 'CALCULATE_RATIO',
  CALCULATE_WIDTH: 'CALCULATE_WIDTH',
  INITIALISE: 'INITIALISE',
};

export interface ActionWithPayload extends Action {
  payload?: any;
}

export function ratioReducer (
  currentState: Ratio,
  action: ActionWithPayload,
) {
  const state: Ratio = Object.assign({}, currentState, action.payload);

  if (isNaN(state.height) || isNaN(state.width) || isNaN(state.denominator) || isNaN(state.numerator)) {
    return currentState;
  }

  switch (action.type) {
    case RATIO_ACTIONS.INITIALISE:
      return state;
    case RATIO_ACTIONS.CALCULATE_HEIGHT:
      state.height = Math.round(state.width * (state.numerator / state.denominator));
      return state;
    case RATIO_ACTIONS.CALCULATE_WIDTH:
      state.width = Math.round(state.height * (state.denominator / state.numerator));
      return state;
    case RATIO_ACTIONS.CALCULATE_RATIO:
      if (state.height == null || state.width == null) {
        return state;
      }

      const fraction: Fraction = new Fraction(state.height, state.width);
      state.numerator = Math.round(fraction.numerator);
      state.denominator = Math.round(fraction.denominator);
      return state;
    default:
      return state;
  }
}
