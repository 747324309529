import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';

import { SidebarModule } from './sidebar/sidebar.module';

import { GoogleAnalyticsEventsService } from './google-analytics-events.service';

import { AppComponent } from './app.component';
import { StageComponent } from './stage/stage.component';
import { ratioReducer } from './reducers/ratio.reducer';

@NgModule({
  declarations: [
    AppComponent,
    StageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SidebarModule,
    StoreModule.forRoot({ ratio: ratioReducer }),
  ],
  providers: [
    GoogleAnalyticsEventsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
