import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, HostBinding } from '@angular/core';
import { GoogleAnalyticsEventsService } from './../google-analytics-events.service';
import { Ratio } from './../ratio';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-stage',
  templateUrl: './stage.component.html',
})
export class StageComponent {

  @Input() ratio: Ratio;
  @HostBinding('class.stage--fun') fun = true;

  public infoVisible = false;
  public particlesVisible = false;
  public particlesTimeout: number;
  public budColor: string;
  private budColors = ['#BDADEA', '#40F99B', '#42E6FF', '#FF88DC', '#FAFF7F'];
  // Halloween 2017 colours
  // private budColors = ['#FC9E4F', '#E67F0D', '#FC9E4F', '#A1C181', '#A1C181'];
  public budFace: string;
  private budFaces = ['0 0', '0 100%', '100% 0', '100% 100%'];

  public constructor(
    private _changeRef: ChangeDetectorRef,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
  ) {}

  public particles(): number[] {
    const arrayOfNumbers: number[] = [];

    for (let i = 0; i < 40; i++) {
      arrayOfNumbers.push(i + 1);
    }
    return arrayOfNumbers;
  }

  public calculatedPaddingTop(): string {
    this.budColor = this.budColors[Math.floor(Math.random() * this.budColors.length)];
    this.budFace = this.budFaces[Math.floor(Math.random() * this.budFaces.length)];
    return !this.ratio ? '100%' : `${(this.ratio.numerator / this.ratio.denominator) * 100}%`;
  }

  public toggleFun(): void {
    this.fun = !this.fun;
    this.showParticles();
    this.submitGAEvent('click', 'toggleFun', this.fun ? 1 : 0);
  }

  public showInfo(): void {
    this.infoVisible = true;
    this.submitGAEvent('click', 'showInfo');
  }

  private showParticles(): void {
    this.particlesVisible = true;
    this.particlesTimeout = window.setTimeout(() => {
      this.particlesVisible = false;
      this._changeRef.markForCheck();
    }, 3000);
  }

  private submitGAEvent(action: string, label?: string, value?: number): void {
    this.googleAnalyticsEventsService.emitEvent('stage', action, label, value);
  }

}
