import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './ratio.state';
import { Ratio } from './ratio';
import { RATIO_ACTIONS } from './reducers/ratio.reducer';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public ratio: Observable<Ratio>;

  public constructor(store: Store<AppState>) {

    this.ratio = store.select(state => state.ratio);

    const initRatio: Ratio = {
      denominator: 16,
      height: 608,
      numerator: 9,
      width: 1080,
    };

    store.dispatch({
      type: RATIO_ACTIONS.INITIALISE,
      payload: initRatio
    });
  }
}
