import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Ratio } from './../../ratio';
import { RATIO_ACTIONS } from './../../reducers/ratio.reducer';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
})
export class ControlComponent {

  @Input() ratio: Ratio;
  @Output() action = new EventEmitter();
  public locked = false;

  public changeWidth(widthInput: string): void {
    const width = parseInt(widthInput, 10);
    if (width === 0) { return; }
    const actionType = (this.locked ? RATIO_ACTIONS.CALCULATE_HEIGHT : RATIO_ACTIONS.CALCULATE_RATIO);
    return this.action.emit({
      type: actionType,
      payload: { width: width }
    });
  }

  public changeHeight(heightInput: string): void {
    const height = parseInt(heightInput, 10);
    if (height === 0) { return; }
    const actionType = (this.locked ? RATIO_ACTIONS.CALCULATE_WIDTH : RATIO_ACTIONS.CALCULATE_RATIO);
    return this.action.emit({
      type: actionType,
      payload: { height }
    });
  }

  public changeDenominator(denominatorInput: string): void {
    const denominator = parseInt(denominatorInput, 10);
    if (denominator === 0) { return; }
    this.locked = false;
    return this.action.emit({
      type: RATIO_ACTIONS.CALCULATE_WIDTH,
      payload: { denominator }
    });
  }

  public changeNumerator(numeratorInput: string) {
    const numerator = parseInt(numeratorInput, 10);
    if (numerator === 0) { return; }
    this.locked = false;
    return this.action.emit({
      type: RATIO_ACTIONS.CALCULATE_HEIGHT,
      payload: { numerator }
    });
  }

}
