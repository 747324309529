import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { GoogleAnalyticsEventsService } from './../../google-analytics-events.service';
import { Ratio } from './../../ratio';

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
})
export class OutputComponent {
  @Input() ratio: Ratio;

  public constructor(
    private clipboardService: ClipboardService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
  ) {}

  public copySCSS(): void {
    this.clipboardService.copyFromContent(this.generateSCSS());
    this.submitGAEvent();
  }

  private submitGAEvent(): void {
    this.googleAnalyticsEventsService.emitEvent('output', 'click', 'copy scss');
  }

  public generateSCSS(): string {
    let denominator = 100;
    let numerator = 100;

    if (this.ratio != null) {
      denominator = this.ratio.denominator;
      numerator = this.ratio.numerator;
    }

    return `.outer {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (${numerator} / ${denominator}) * 100%;
  }
  > .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}`;
  }
}
