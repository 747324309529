export class Fraction {

  constructor(public numerator: number, public denominator: number) {
    this.euclid(this.numerator, this.denominator);
  }

  private euclid(a: number, b: number) {
    let c: number, d: number;
    if (a < b) {
      c = b;
      d = a;
    } else {
      c = a % b;
      if (c === 0) {
        this.numerator /= b;
        this.denominator /= b;
        return;
      }
      if (c === 1) {
        return;
      }
      d = b;
    }
    this.euclid(c, d);
  }
}
