import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './../ratio.state';
import { Ratio } from './../ratio';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {

  public ratio: Observable<Ratio>;

  constructor(public store: Store<AppState>) {
    this.ratio = store.select(state => state.ratio);
  }

}
