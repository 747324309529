import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

import { ControlComponent } from './control/control.component';
import { SidebarComponent } from './sidebar.component';
import { OutputComponent } from './output/output.component';

@NgModule({
  declarations: [
    ControlComponent,
    OutputComponent,
    SidebarComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    FormsModule,
  ],
  exports: [
    SidebarComponent
  ]
})
export class SidebarModule {}
